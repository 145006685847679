import * as React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import {
  FArticleInterface,
  FArticleSmallInterface,
} from "../../../interfaces/FArticleInterface";
import { CONDITION_OR } from "../../../utils/FilterUtils";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SliderFArticleComponent from "../../slider/SliderFArticleComponent";

interface State {
  fArticle: FArticleInterface | undefined;
}

const FArticleDisplayEnsembleComponent = React.memo(
  React.forwardRef(({ fArticle }: State, ref) => {
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);

    const [fArticles, setFArticles] = React.useState<
      FArticleSmallInterface[] | undefined
    >(undefined);

    const updateEnsembles = React.useCallback(async () => {
      if (!fArticle?.fArticleProp?.ensembles) {
        return;
      }
      if (fArticle?.fArticleProp?.ensembles.length === 0) {
        setFArticles([]);
        return;
      }
      setFArticles(undefined);
      const response = await requestApi({
        method: GET,
        path:
          FARTICLE_URL +
          objectToQuery({
            "ensembles[]": JSON.stringify({
              condition: CONDITION_OR,
              values: fArticle.fArticleProp.ensembles.map((e) => e.id),
            }),
          }),
        allowError: false,
        paginate: false,
        token: token,
      });
      if (response.statusCode === 200) {
        setFArticles(
          response.content.fArticles.filter(
            (fArticleSmall: FArticleSmallInterface) =>
              fArticleSmall.arRef !== fArticle.arRef
          )
        );
      } else {
        setFArticles([]);
        if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
      }
    }, [fArticle?.arRef, fArticle?.fArticleProp?.ensembles, t, token]);

    React.useEffect(() => {
      updateEnsembles();
    }, [JSON.stringify(fArticle?.fArticleProp?.ensembles)]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {fArticles !== undefined && fArticles.length > 0 && (
          <Box>
            <Typography>{t("word.alternate")}</Typography>
            <SliderFArticleComponent fArticles={fArticles} />
          </Box>
        )}
      </>
    );
  })
);

export default FArticleDisplayEnsembleComponent;
