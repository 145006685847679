import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { InputInterface } from "../../interfaces/InputInterface";
import { Grid, TextField } from "@mui/material";

interface State {
  data: any;
}

interface FormState {
  url: InputInterface;
}

const IframeContentComponent = React.memo(
  forwardRef(({ data }: State, ref) => {
    const { t } = useTranslation();
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        url: { value: data?.url ?? "", error: "" },
      };
    }, [data]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        let r: any = {};
        for (const key in values) {
          // @ts-ignore
          r[key] = values[key].value;
        }
        return r;
      },
    }));

    useEffect(() => {
      setValues(getDefaultValues());
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box sx={{ marginY: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth={true}
              autoComplete="off"
              error={!!values.url.error}
              helperText={t(values.url.error ?? "")}
              sx={{ height: "100%" }}
              type="url"
              value={values.url.value}
              onChange={handleChange("url")}
              label={t("field.url")}
            />
          </Grid>
        </Grid>
      </Box>
    );
  })
);

export default IframeContentComponent;
