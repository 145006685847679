import * as React from "react";
import ContentDisplayComponent from "../../content/ContentDisplayComponent";
import { CategoryInterface } from "../../../interfaces/CategoryInterface";

interface State {
  category?: CategoryInterface;
}

const FArticleHeaderComponent: React.FC<State> = React.memo(({ category }) => {
  return (
    <>{category && <ContentDisplayComponent content={category.content} />}</>
  );
});

export default FArticleHeaderComponent;
