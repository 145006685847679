import React from "react";
import Layout from "../../components/common/Layout";
import { getTitle, searchParamToObject } from "../../helpers/SearchParamHelper";
import LoginComponent from "../../components/common/user/LoginComponent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanelComponent from "../../components/common/TabPanelComponent";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import PdfUploadComponent from "../../components/common/pdf/PdfUploadComponent";
import PdfUploadFileComponent from "../../components/common/pdf/PdfUploadFileComponent";

const PdfScreen: React.FC = React.memo(() => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const getValue = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    return searchParamsObject.tab ? Number(searchParamsObject.tab) : 0;
  }, [searchParams]);
  const [value, setValue] = React.useState(getValue());
  const handleChange = React.useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
      const newSearchParams = {
        tab: newValue.toString(),
      };
      setSearchParams(newSearchParams, {
        replace: true,
      });
    },
    [setSearchParams]
  );

  React.useEffect(() => {
    document.title = getTitle("pdf");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <LoginComponent redirect={null} requireAdmin={true}>
        <Tabs
          value={value}
          onChange={handleChange}
          scrollButtons="auto"
          variant={!isSmall ? "standard" : "scrollable"}
          centered={!isSmall}
        >
          <Tab label={t("word.catalog")} />
          <Tab label={t("word.pdf")} />
        </Tabs>
        <TabPanelComponent
          sx={{ marginTop: 1 }}
          value={value}
          index={0}
          removeComponentOnHidden={true}
        >
          <PdfUploadComponent />
        </TabPanelComponent>
        <TabPanelComponent
          sx={{ marginTop: 1 }}
          value={value}
          index={1}
          removeComponentOnHidden={true}
        >
          <PdfUploadFileComponent />
        </TabPanelComponent>
      </LoginComponent>
    </Layout>
  );
});

export default PdfScreen;
