import React, { forwardRef, useImperativeHandle } from "react";
import { TextField, useMediaQuery, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";

interface State {
  apply: Function;
  setNbSelected: Function;
  largeArRef?: boolean;
  index?: number;
  filterInited?: Function;
}

const KeywordsFilterComponent = React.memo(
  forwardRef(
    ({ apply, setNbSelected, index, filterInited, largeArRef }: State, ref) => {
      const theme = useTheme();
      const isSmall = useMediaQuery(theme.breakpoints.down("md"));
      const [searchParams] = useSearchParams();
      const [search, setSearch] = React.useState<string>(() => {
        const searchParamsObject = searchParamToObject(searchParams);
        return searchParamsObject.keywords ?? "";
      });

      const handleChangeText = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setSearch(event.target.value);
        },
        []
      );

      const searchKeywords = React.useCallback(() => {
        if (!isSmall) {
          apply();
        }
      }, [apply, isSmall]);

      useImperativeHandle(ref, () => ({
        getValue() {
          if (search.trim() !== "") {
            return {
              keywords: search,
              ...(largeArRef && {
                largeArRef: true,
              }),
            };
          }
          return {};
        },
      }));

      React.useEffect(() => {
        const timeoutTyping = setTimeout(() => {
          searchKeywords();
        }, 500);
        return () => clearTimeout(timeoutTyping);
      }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setNbSelected(search.trim() === "" ? 0 : 1);
        if (filterInited && index !== undefined) {
          filterInited(index);
        }
      }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        const searchParamsObject = searchParamToObject(searchParams);
        setSearch(searchParamsObject?.keywords ?? "");
      }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <TextField
          fullWidth
          autoComplete="off"
          type="text"
          value={search}
          onChange={handleChangeText}
        />
      );
    }
  )
);

export default KeywordsFilterComponent;
