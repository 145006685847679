import React, { forwardRef, useImperativeHandle, useRef } from "react";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import CkEditorComponent from "./CkEditorComponent";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  CONTENT_CAROUSEL,
  CONTENT_CKEDITOR,
  CONTENT_FARTICLE,
  CONTENT_IFRAME,
  CONTENT_MAP,
  CONTENT_PDF,
  CONTENT_VIDEO,
} from "../../utils/ContentUtils";
import AppsIcon from "@mui/icons-material/Apps";
import SearchProductComponent from "./SearchProduct/SearchProductComponent";
import MapIcon from "@mui/icons-material/Map";
import MapContentComponent from "./MapContentComponent";
import { FArticleSmallInterface } from "../../interfaces/FArticleInterface";
import PdfContentComponent from "./PdfContentComponent";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { useTranslation } from "react-i18next";
import { InputInterface } from "../../interfaces/InputInterface";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import CarouselContentComponent from "./CarouselContentComponent";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import VideoContentComponent from "./VideoContentComponent";
import Divider from "@mui/material/Divider";
import IframeContentComponent from "./IframeContentComponent";
import WebIcon from "@mui/icons-material/Web";

interface State {
  index: number;
  removeColumn: Function;
  data: any;
  fArticle: FArticleSmallInterface | undefined;
  availableModules?: string[];
}

interface FormState {
  contentType: string;
  isAccordion: InputInterface;
  isNotActive: InputInterface;
  dismissible: InputInterface;
}

const ColumnComponent = React.memo(
  forwardRef(
    ({ removeColumn, index, data, fArticle, availableModules }: State, ref) => {
      const ckEditorRef: any = useRef();
      const ckEditorRefAccordion: any = useRef();
      const searchProductRef: any = useRef();
      const mapRef: any = useRef();
      const videoRef: any = useRef();
      const iFrameRef: any = useRef();
      const pdfRef: any = useRef();
      const carouselRef: any = useRef();
      const getValues = React.useCallback(() => {
        return {
          contentType: data?.contentType ?? CONTENT_CKEDITOR,
          isAccordion: { value: !!data?.accordion, error: "" },
          isNotActive: { value: !!data?.notActive, error: "" },
          dismissible: { value: !!data?.dismissible, error: "" },
        };
      }, [
        data?.contentType,
        data?.accordion,
        data?.notActive,
        data?.dismissible,
      ]);
      const [values, setValues] = React.useState<FormState>(getValues());
      const [defaultContent, setDefaultContent] =
        React.useState<any>(undefined);
      const [defaultContentType, setDefaultContentType] =
        React.useState<any>(undefined);
      const [defaultData, setDefaultData] = React.useState<any>(undefined);
      const { t } = useTranslation();
      const thisRemoveColumn = React.useCallback(() => {
        removeColumn(index);
      }, [index, removeColumn]);
      const handleChangeCheckbox = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                // @ts-ignore
                [prop]: { ...v[prop], value: event.target.checked, error: "" },
              };
            });
          },
        []
      );

      const initContent = React.useCallback(() => {
        if (
          data === null ||
          (defaultContent !== undefined && defaultContentType !== undefined)
        ) {
          return;
        }
        setDefaultContent(data.data);
        setDefaultContentType(data.contentType);
        setValues(getValues());
      }, [data, defaultContent, defaultContentType, getValues]);

      const changeContentType = React.useCallback(
        (contentType: string) => {
          setValues((values: FormState) => {
            values.contentType = contentType;
            return { ...values };
          });
        },
        [setValues]
      );

      useImperativeHandle(ref, () => ({
        async getValue() {
          let data = null;
          if (values.contentType === CONTENT_CKEDITOR) {
            data = ckEditorRef.current.getValue();
          }
          if (values.contentType === CONTENT_FARTICLE) {
            data = searchProductRef.current.getValue();
          }
          if (values.contentType === CONTENT_MAP) {
            data = mapRef.current.getValue();
          }
          if (values.contentType === CONTENT_VIDEO) {
            data = videoRef.current.getValue();
          }
          if (values.contentType === CONTENT_IFRAME) {
            data = iFrameRef.current.getValue();
          }
          if (values.contentType === CONTENT_PDF) {
            data = await pdfRef.current.getValue();
          }
          if (values.contentType === CONTENT_CAROUSEL) {
            data = await carouselRef.current.getValue();
          }
          if (data === undefined) {
            return undefined;
          }
          // @ts-ignore
          return {
            contentType: values.contentType,
            data: data,
            accordion: values.isAccordion.value
              ? ckEditorRefAccordion.current.getValue()
              : false,
            notActive: values.isNotActive.value,
            dismissible: values.dismissible.value,
          };
        },
      }));

      React.useEffect(() => {
        initContent();
      }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setDefaultData(
          defaultContentType === undefined ||
            values.contentType === defaultContentType
            ? defaultContent
            : undefined
        );
      }, [defaultContent, defaultContentType, values.contentType]);

      return (
        <Box sx={{ padding: 1, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {(!availableModules ||
                availableModules.includes(CONTENT_CKEDITOR)) && (
                <Box>
                  <LoadingButton
                    variant={
                      values.contentType === CONTENT_CKEDITOR
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => changeContentType(CONTENT_CKEDITOR)}
                  >
                    <TextSnippetIcon />
                  </LoadingButton>
                </Box>
              )}
              {(!availableModules ||
                availableModules.includes(CONTENT_FARTICLE)) && (
                <Box sx={{ marginLeft: 1 }}>
                  <LoadingButton
                    variant={
                      values.contentType === CONTENT_FARTICLE
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => changeContentType(CONTENT_FARTICLE)}
                  >
                    <AppsIcon />
                  </LoadingButton>
                </Box>
              )}
              {(!availableModules ||
                availableModules.includes(CONTENT_MAP)) && (
                <Box sx={{ marginLeft: 1 }}>
                  <LoadingButton
                    variant={
                      values.contentType === CONTENT_MAP
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => changeContentType(CONTENT_MAP)}
                  >
                    <MapIcon />
                  </LoadingButton>
                </Box>
              )}
              {(!availableModules ||
                availableModules.includes(CONTENT_PDF)) && (
                <Box sx={{ marginLeft: 1 }}>
                  <LoadingButton
                    variant={
                      values.contentType === CONTENT_PDF
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => changeContentType(CONTENT_PDF)}
                  >
                    <PictureAsPdfIcon />
                  </LoadingButton>
                </Box>
              )}
              {(!availableModules ||
                availableModules.includes(CONTENT_CAROUSEL)) && (
                <Box sx={{ marginLeft: 1 }}>
                  <LoadingButton
                    variant={
                      values.contentType === CONTENT_CAROUSEL
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => changeContentType(CONTENT_CAROUSEL)}
                  >
                    <BurstModeIcon />
                  </LoadingButton>
                </Box>
              )}
              {(!availableModules ||
                availableModules.includes(CONTENT_VIDEO)) && (
                <Box sx={{ marginLeft: 1 }}>
                  <LoadingButton
                    variant={
                      values.contentType === CONTENT_VIDEO
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => changeContentType(CONTENT_VIDEO)}
                  >
                    <OndemandVideoIcon />
                  </LoadingButton>
                </Box>
              )}
              {(!availableModules ||
                availableModules.includes(CONTENT_IFRAME)) && (
                <Box sx={{ marginLeft: 1 }}>
                  <LoadingButton
                    variant={
                      values.contentType === CONTENT_IFRAME
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => changeContentType(CONTENT_IFRAME)}
                  >
                    <WebIcon />
                  </LoadingButton>
                </Box>
              )}
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isAccordion.value}
                      onChange={handleChangeCheckbox("isAccordion")}
                    />
                  }
                  label={t("word.accordion")}
                />
              </FormGroup>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isNotActive.value}
                      onChange={handleChangeCheckbox("isNotActive")}
                    />
                  }
                  label={t("word.notActive")}
                />
              </FormGroup>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.dismissible.value}
                      onChange={handleChangeCheckbox("dismissible")}
                    />
                  }
                  label={t("word.dismissible")}
                />
              </FormGroup>
            </Box>
            <Box>
              <LoadingButton variant="outlined" onClick={thisRemoveColumn}>
                <DeleteOutlineIcon />
              </LoadingButton>
            </Box>
          </Box>
          <Divider sx={{ marginY: 0.5 }} />
          {values.isAccordion.value && (
            <Box sx={{ marginBottom: 1 }}>
              <CkEditorComponent
                ref={ckEditorRefAccordion}
                text={data?.accordion ?? ""}
                defaultTitleSelect={1}
                defaultAlignment="center"
              />
            </Box>
          )}
          {values.contentType === CONTENT_CKEDITOR ? (
            <CkEditorComponent ref={ckEditorRef} text={defaultData} />
          ) : values.contentType === CONTENT_FARTICLE ? (
            <SearchProductComponent
              ref={searchProductRef}
              data={defaultData}
              fArticle={fArticle}
            />
          ) : values.contentType === CONTENT_MAP ? (
            <MapContentComponent ref={mapRef} data={defaultData} />
          ) : values.contentType === CONTENT_PDF ? (
            <PdfContentComponent ref={pdfRef} data={defaultData} />
          ) : values.contentType === CONTENT_CAROUSEL ? (
            <CarouselContentComponent ref={carouselRef} data={defaultData} />
          ) : values.contentType === CONTENT_VIDEO ? (
            <VideoContentComponent ref={videoRef} data={defaultData} />
          ) : values.contentType === CONTENT_IFRAME ? (
            <IframeContentComponent ref={iFrameRef} data={defaultData} />
          ) : (
            <></>
          )}
        </Box>
      );
    }
  )
);

export default ColumnComponent;
