import * as React from "react";
import { SyntheticEvent, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { Autocomplete, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { InputInterface } from "../../../interfaces/InputInterface";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { FArticleInterface } from "../../../interfaces/FArticleInterface";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { FFamilleInterface } from "../../../interfaces/ConfiguratorEnergyInterface";
import { grey } from "@mui/material/colors";

interface State {
  edit: boolean;
  setFArticle: Function;
  createFrom?: FArticleInterface;
  fArticle: FArticleInterface | undefined;
}

interface FormState {
  fFamille: InputInterface;
}

const FArticleFaCodefamilleComponent = React.memo(
  React.forwardRef(
    ({ edit, setFArticle, createFrom, fArticle }: State, ref) => {
      const { t } = useTranslation();
      const fFamilles = useAppSelector(
        (state: RootState) => state.globalState.fFamilles
      );
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const theme = useTheme();

      const getDefaultValues = React.useCallback((): FormState => {
        const thisArticle = createFrom ?? fArticle;
        return {
          fFamille: {
            value:
              fFamilles?.find(
                (f) => f.faCodefamille === thisArticle?.faCodefamille
              ) ?? null,
            error: "",
          },
        };
      }, [createFrom, fArticle, fFamilles]);
      const [values, setValues] = React.useState<FormState>(getDefaultValues());

      const [thisEdit, setThisEdit] = React.useState(
        edit !== undefined ? edit : false
      );
      const [loading, setLoading] = React.useState(false);
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );

      const handleThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);

      const getValue = React.useCallback(() => {
        return {
          faCodefamille: values.fFamille.value.faCodefamille,
        };
      }, [values.fFamille.value]);

      const save = React.useCallback(async () => {
        setLoading(true);
        const fArticleApi = new FormData();
        const thisValues = getValue();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            ...thisValues,
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          setFArticle(response.content);
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [fArticle?.arRef, getValue, setFArticle, t, token]);

      const handleChangeAutocomplete = React.useCallback(
        (prop: keyof FormState) =>
          (event: SyntheticEvent<Element, Event>, value: any) => {
            setValues((v) => {
              return {
                ...v,
                // @ts-ignore
                [prop]: { ...v[prop], value: value, error: "" },
              };
            });
          },
        []
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        if (edit !== undefined) {
          setThisEdit(edit);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues(getDefaultValues());
      }, [createFrom, fArticle]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {!(thisEdit || edit) ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ width: "100%" }}>
                <span style={{ color: grey[500] }}>
                  {t("field.family") + ": "}
                </span>
                {fArticle?.faCodefamille +
                  " " +
                  fFamilles?.find(
                    (f) => f.faCodefamille === fArticle?.faCodefamille
                  )?.faIntitule ?? ""}
                {isAdmin && (
                  <IconButton onClick={handleThisEdit}>
                    <EditIcon />
                  </IconButton>
                )}
              </Typography>
            </Box>
          ) : (
            <>
              <Autocomplete
                fullWidth
                options={fFamilles ?? []}
                getOptionLabel={(option: FFamilleInterface) => {
                  return (
                    (option.faCodefamille ?? "") +
                    " " +
                    (option.faIntitule ?? "")
                  );
                }}
                isOptionEqualToValue={(
                  option: FFamilleInterface,
                  value: FFamilleInterface
                ) => {
                  return option.faCodefamille === value.faCodefamille;
                }}
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      key={option.faCodefamille}
                      style={{ display: "block" }}
                    >
                      <span
                        style={{
                          color: theme.palette.primary.main,
                          fontWeight: "bold",
                        }}
                      >
                        {option.faCodefamille}
                      </span>{" "}
                      {option.faIntitule}
                    </li>
                  );
                }}
                onChange={handleChangeAutocomplete("fFamille")}
                value={values.fFamille.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete="off"
                    error={!!values.fFamille.error}
                    helperText={t(values.fFamille.error ?? "")}
                    label={t("word.fFamille")}
                    placeholder={t("word.fFamille")}
                  />
                )}
              />
              {!edit && (
                <>
                  <Tooltip title={t("word.cancel")}>
                    <IconButton onClick={handleThisEdit} disabled={loading}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("word.save")}>
                    <LoadingButton
                      variant="text"
                      color="inherit"
                      sx={{
                        borderRadius: "50%",
                        minWidth: "auto",
                        padding: "8px",
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                      loading={loading}
                      onClick={save}
                    >
                      <SaveIcon />
                    </LoadingButton>
                  </Tooltip>
                </>
              )}
            </>
          )}
        </>
      );
    }
  )
);

export default FArticleFaCodefamilleComponent;
