import * as React from "react";
import { useImperativeHandle, useRef } from "react";
import { FArticleInterface } from "../../../interfaces/FArticleInterface";
import ContentComponent from "../../content/ContentComponent";
import { Container, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { ContentInterface } from "../../../interfaces/ContentInterface";
import { generateTechnicalSheet } from "../../../helpers/FArticleHelper";

interface State {
  fArticle: FArticleInterface | undefined;
  setFArticle: Function;
  edit: boolean;
  field: string;
  createFrom?: FArticleInterface;
  temporaryIcecat?: any;
}

const FArticleDescriptionComponent = React.memo(
  React.forwardRef(
    (
      {
        fArticle,
        edit,
        setFArticle,
        createFrom,
        temporaryIcecat,
        field,
      }: State,
      ref
    ) => {
      const { t } = useTranslation();
      const refContent: any = useRef();
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const getInitContent = React.useCallback(():
        | ContentInterface
        | null
        | undefined => {
        const newContent: any = {
          content: {
            tabs: [
              {
                name: "",
                rows: [
                  {
                    container: "A",
                    columns: [
                      {
                        contentType: "A",
                        data: "",
                      },
                    ],
                  },
                ],
                sort: 0,
              },
            ],
          },
        };
        if (temporaryIcecat && field === "description") {
          const temp: any = {
            ficheTechnique: temporaryIcecat,
          };
          const technicalSheet = generateTechnicalSheet(temp, 1);
          if (technicalSheet?.description) {
            newContent.content.tabs[0].rows[0].columns[0].data =
              technicalSheet.description;
            return newContent;
          }
        }
        if (createFrom) {
          return newContent;
        }
        if (fArticle?.fArticleProp) {
          // @ts-ignore
          return fArticle?.fArticleProp[field];
        }
        return undefined;
      }, [createFrom, fArticle?.fArticleProp, field, temporaryIcecat]);
      const [initContent, setInitContent] = React.useState<
        ContentInterface | null | undefined
      >(getInitContent());
      const save = React.useCallback(async () => {
        const fArticleApi = new FormData();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            fArticleProp: {
              [field]: await refContent.current.getValue(),
            },
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response !== null) {
          if (response.statusCode === 200 || response.statusCode === 201) {
            setFArticle(response.content);
            refContent.current.setEdit(false);
          }
        } else {
          toastr.error(t("word.error"), t("error.tryAgain"));
        }
      }, [fArticle?.arRef, setFArticle, t, token]);

      const resetFArticleInitContent = React.useCallback(() => {
        setInitContent(getInitContent());
      }, [getInitContent]);

      useImperativeHandle(ref, () => ({
        async getValue() {
          return await refContent.current.getValue();
        },
        setDescription(newContent: string | undefined) {
          if (newContent) {
            let data = newContent
              .replaceAll("<b>", "<b></p>")
              .replaceAll("</b>", "</b><p>")
              .replaceAll("\\n", "")
              .trim();
            if (data.startsWith("<b></p>")) {
              data = data.replace("<b></p>", "<b>");
            }
            setInitContent({
              // @ts-ignore
              tabs: [
                {
                  name: "",
                  rows: [
                    {
                      container: "A",
                      columns: [
                        {
                          contentType: "A",
                          data: data.trim(),
                          accordion: false,
                        },
                      ],
                    },
                  ],
                  sort: 0,
                },
              ],
            });
            setTimeout(() => {
              refContent.current.setEdit(true);
            }, 100);
          }
        },
      }));

      React.useEffect(() => {
        resetFArticleInitContent();
      }, [
        fArticle?.fArticleProp?.description,
        fArticle?.fArticleProp?.technicalSupport,
        temporaryIcecat,
        createFrom,
      ]);

      let thisContent: ContentInterface | null | undefined = undefined;
      if (fArticle?.fArticleProp) {
        // @ts-ignore
        thisContent = fArticle?.fArticleProp[field];
      }

      return thisContent !== undefined || !!createFrom ? (
        <ContentComponent
          initContent={initContent}
          defaultMessage={
            <Container>
              <Typography>{t("sentence.noDescription")}</Typography>
            </Container>
          }
          preventChangeUrlTab={true}
          resetFArticleInitContent={resetFArticleInitContent}
          fArticle={fArticle}
          showEdit={true}
          edit={edit}
          ref={refContent}
          showSaveCancel={!edit}
          customSave={save}
        />
      ) : (
        <Skeleton
          variant="rectangular"
          height={150}
          sx={{ marginTop: 2, marginBottom: 2 }}
        />
      );
    }
  )
);

export default FArticleDescriptionComponent;
