import * as React from "react";
import { SyntheticEvent, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { Autocomplete, Skeleton, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { InputInterface } from "../../../interfaces/InputInterface";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { FArticleInterface } from "../../../interfaces/FArticleInterface";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import CopyClipboardComponent from "../CopyClipboardComponent";

interface State {
  edit: boolean;
  setFArticle: Function;
  createFrom?: FArticleInterface;
  fArticle: FArticleInterface | undefined;
}

interface FormState {
  ensembles: InputInterface;
}

const FArticleEnsembleComponent = React.memo(
  React.forwardRef(
    ({ edit, setFArticle, createFrom, fArticle }: State, ref) => {
      const { t } = useTranslation();
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );

      const getDefaultValues = React.useCallback((): FormState => {
        const thisArticle = createFrom ?? fArticle;
        return {
          ensembles: {
            value: thisArticle?.fArticleProp?.ensembles?.map((e) => {
              return e.text;
            }),
            error: "",
          },
        };
      }, [createFrom, fArticle]);
      const [values, setValues] = React.useState<FormState>(getDefaultValues());

      const [thisEdit, setThisEdit] = React.useState(
        edit !== undefined ? edit : false
      );
      const [loading, setLoading] = React.useState(false);
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );

      const handleThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);

      const getValue = React.useCallback(() => {
        return {
          ensembles: values.ensembles.value.map((s: string) => {
            return {
              text: s,
            };
          }),
        };
      }, [values.ensembles.value]);

      const save = React.useCallback(async () => {
        setLoading(true);
        const fArticleApi = new FormData();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            fArticleProp: getValue(),
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          setFArticle(response.content);
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [fArticle?.arRef, getValue, setFArticle, t, token]);

      const handleChangeAutocomplete = React.useCallback(
        (prop: keyof FormState) =>
          (event: SyntheticEvent<Element, Event>, value: any) => {
            setValues((v) => {
              return {
                ...v,
                // @ts-ignore
                [prop]: { ...v[prop], value: value, error: "" },
              };
            });
          },
        []
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        if (edit !== undefined) {
          setThisEdit(edit);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues(getDefaultValues());
      }, [createFrom, fArticle]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {isAdmin && (
            <>
              {!(thisEdit || edit) ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ width: "100%" }}>
                    <span style={{ color: grey[500] }}>
                      {t("field.ensemble") + ": "}
                    </span>
                    {fArticle?.fArticleProp === undefined ? (
                      <Skeleton sx={{ flex: 1 }} variant="text" />
                    ) : (
                      fArticle?.fArticleProp?.ensembles?.map((e, indexE) => {
                        return (
                          <CopyClipboardComponent
                            key={indexE}
                            className="RobotoMono"
                            component="span"
                            text={e.text}
                            sx={{ marginLeft: 1 }}
                          />
                        );
                      })
                    )}
                    {isAdmin && (
                      <IconButton onClick={handleThisEdit}>
                        <EditIcon />
                      </IconButton>
                    )}
                  </Typography>
                </Box>
              ) : (
                <>
                  <Autocomplete
                    fullWidth
                    multiple
                    freeSolo
                    options={[]}
                    onChange={handleChangeAutocomplete("ensembles")}
                    value={values.ensembles.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        error={!!values.ensembles.error}
                        helperText={t(values.ensembles.error ?? "")}
                        label={t("word.ensemble")}
                        placeholder={t("word.ensemble")}
                      />
                    )}
                  />
                  {!edit && (
                    <>
                      <Tooltip title={t("word.cancel")}>
                        <IconButton onClick={handleThisEdit} disabled={loading}>
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("word.save")}>
                        <LoadingButton
                          variant="text"
                          color="inherit"
                          sx={{
                            borderRadius: "50%",
                            minWidth: "auto",
                            padding: "8px",
                            color: "rgba(0, 0, 0, 0.54)",
                          }}
                          loading={loading}
                          onClick={save}
                        >
                          <SaveIcon />
                        </LoadingButton>
                      </Tooltip>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      );
    }
  )
);

export default FArticleEnsembleComponent;
