import * as React from "react";
import {
  FArticleSmallInterface,
  IcecatSmallProductInterface,
} from "../../../interfaces/FArticleInterface";
import {
  Card,
  CardContent,
  Chip,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET, POST } from "../../../utils/MethodUtils";
import {
  ICECAT_IMAGE_URL,
  ICECAT_PRODUCT_LIST_URL,
} from "../../../utils/UrlsUtils";
import IconButton from "@mui/material/IconButton";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import SearchComponent from "../SearchComponent";
import Typography from "@mui/material/Typography";
import HideImageIcon from "@mui/icons-material/HideImage";
import CircularProgress from "@mui/material/CircularProgress";
import { LoadingButton } from "@mui/lab";
import DoneIcon from "@mui/icons-material/Done";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { updateLoginCookie } from "../../../helpers/FArticleHelper";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  updateIcecat: Function;
}

interface State2 {
  initSearch: string;
  updateIcecat: Function;
  currentIcecatId?: string | null;
  hideSearch?: boolean;
}

interface FilterSearchProducts {
  products: IcecatSmallProductInterface[] | undefined;
  searchImages: boolean;
}

export const FilterSearchIcecatComponent: React.FC<State2> = React.memo(
  ({ initSearch, updateIcecat, currentIcecatId, hideSearch }) => {
    const { t } = useTranslation();
    const [search, setSearch] = React.useState<string>(initSearch);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const loginCookieIcecat = useAppSelector(
      (state: RootState) => state.globalState.loginCookieIcecat
    );
    const [products, setProducts] = React.useState<FilterSearchProducts>({
      searchImages: false,
      products: undefined,
    });

    const handleChangeText = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
      },
      []
    );

    const clearSearch = React.useCallback(() => {
      setSearch("");
    }, []);

    const onSelectProduct = React.useCallback(
      async (icecatId: number | null = null, notFound = false) => {
        setSearch("");
        updateIcecat(icecatId, notFound);
      },
      [updateIcecat]
    );

    const updateImages = React.useCallback(async () => {
      if (!products.searchImages || !loginCookieIcecat || !products.products) {
        return;
      }
      for (const p of products.products) {
        if (p.base64 !== undefined) {
          continue;
        }
        requestApi({
          method: POST,
          path: ICECAT_IMAGE_URL.replace("{id}", p.product_id.toString()),
          allowError: true,
          token: token,
          body: loginCookieIcecat,
        }).then((response) => {
          setProducts((allProducts) => {
            if (!allProducts.products) {
              return allProducts;
            }
            allProducts.searchImages = false;
            const thisP = allProducts.products.find(
              (thisP) => thisP.product_id === p.product_id
            );
            if (thisP) {
              thisP.base64 = response?.content?.base64 ?? "";
            }
            return { ...allProducts };
          });
        });
      }
    }, [loginCookieIcecat, products, token]);

    const searchProducts = React.useCallback(async () => {
      const thisSearch = search.trim();
      if (!thisSearch) {
        return;
      }
      const response = await requestApi({
        method: GET,
        path:
          ICECAT_PRODUCT_LIST_URL +
          objectToQuery({
            search: thisSearch,
            crossDomainAuth:
              loginCookieIcecat
                ?.find((x: string) => x.includes("crossDomainAuth="))
                ?.replace("crossDomainAuth=", "") ?? "",
          }),
        allowError: true,
        token: token,
      });
      if (response.statusCode === 200) {
        // test avec http://localhost:3000/informatique-guadeloupe/product/tablette-galaxy-tab-a7-10-4-3go-32go-wifi-microsd-gris
        // https://icecat.biz/images.icecat.biz/get_image.cgi?product_id=14639906&type_image=thumb&product_gallery_id=47958832
        setProducts({
          searchImages: true,
          products: response.content.products.map(
            (p: IcecatSmallProductInterface) => {
              return {
                ...p,
                base64: undefined,
              };
            }
          ),
        });
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
    }, [loginCookieIcecat, search, t, token]);

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        searchProducts();
      }, 500);
      return () => clearTimeout(timeoutTyping);
    }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (!products.searchImages) {
        return;
      }
      updateImages();
    }, [products.searchImages]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setSearch(initSearch);
    }, [initSearch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {!hideSearch && (
          <TextField
            autoComplete="off"
            sx={{ width: "100%" }}
            type="text"
            value={search}
            onChange={handleChangeText}
            label={t("word.search")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearch}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        {search.trim() && (
          <>
            {products.products ? (
              <Grid container spacing={1}>
                {products.products.map((product, indexProduct) => {
                  const thisSearch = search.trim().toLowerCase();
                  const mpn = product.mpn.toLowerCase();
                  const ean = product.ean.toLowerCase();
                  return (
                    <Grid item xs={12} md={3} key={indexProduct}>
                      <Card variant="outlined">
                        <CardContent>
                          {product.product_id.toString() ===
                            currentIcecatId?.toString() && (
                            <Chip
                              color="primary"
                              label={t("word.current")}
                              size="small"
                              sx={{ cursor: "pointer" }}
                            />
                          )}
                          {thisSearch === mpn || thisSearch === ean ? (
                            <Chip
                              color="success"
                              label={t("word.exact")}
                              size="small"
                              sx={{ cursor: "pointer" }}
                            />
                          ) : (mpn.includes(thisSearch) && mpn !== "") ||
                            (ean.includes(thisSearch) && ean !== "") ? (
                            <Chip
                              color="warning"
                              label={t("word.partial")}
                              size="small"
                              sx={{ cursor: "pointer" }}
                            />
                          ) : (
                            <></>
                          )}
                          {product.base64 === undefined ? (
                            <SearchComponent
                              nbColumn={1}
                              nbLines={1}
                              height={100}
                            />
                          ) : product.base64 === null ? (
                            <HideImageIcon
                              sx={{
                                width: "50%",
                                height: "50%",
                              }}
                            />
                          ) : (
                            <img
                              src={product.base64}
                              alt={product.product_id.toString()}
                            />
                          )}
                          {product?.existSage &&
                            product.existSage.map((ref, indexRef) => (
                              <Chip
                                key={indexRef}
                                label={ref}
                                variant="outlined"
                                color="error"
                              />
                            ))}
                          <Typography variant="caption" component="p">
                            {product.mpn}
                          </Typography>
                          <Divider />
                          <Typography variant="caption" component="p">
                            {product.ean}
                          </Typography>
                          <Divider />
                          <Typography>{product.title}</Typography>
                          <Box sx={{ textAlign: "center" }}>
                            <LoadingButton
                              variant="contained"
                              sx={{
                                borderRadius: "50%",
                                minWidth: "auto",
                                padding: "12px",
                              }}
                              onClick={() => {
                                onSelectProduct(product.product_id);
                              }}
                            >
                              <DoneIcon fontSize="small" />
                            </LoadingButton>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <SearchComponent nbColumn={4} nbLines={2} height={200} />
            )}
          </>
        )}
      </>
    );
  }
);

export const IcecatListProductsComponent = React.memo(
  React.forwardRef(({ fArticle, updateIcecat }: State, ref) => {
    const { t } = useTranslation();
    const loginCookieIcecat = useAppSelector(
      (state: RootState) => state.globalState.loginCookieIcecat
    );

    React.useEffect(() => {
      updateLoginCookie();
    }, [loginCookieIcecat]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {!loginCookieIcecat ? (
          <Typography sx={{ textAlign: "center" }}>
            {t("word.icecatAuth") + " ..."}
            <CircularProgress />
          </Typography>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <LoadingButton
                variant="contained"
                onClick={() => updateIcecat(null, true)}
              >
                {t("word.notExists")}
              </LoadingButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <FilterSearchIcecatComponent
                initSearch={fArticle?.arCodebarre ?? ""}
                updateIcecat={updateIcecat}
                currentIcecatId={fArticle?.fArticleProp?.icecatId}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FilterSearchIcecatComponent
                initSearch={fArticle?.constructeurRef ?? ""}
                updateIcecat={updateIcecat}
                currentIcecatId={fArticle?.fArticleProp?.icecatId}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  })
);
