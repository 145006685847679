import * as React from "react";
import { useRef } from "react";
import Typography from "@mui/material/Typography";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { set } from "../../../../app/globalSlice";
import SearchComponent from "../../SearchComponent";
import { FArticleInterface } from "../../../../interfaces/FArticleInterface";
import { objectToQuery, requestApi } from "../../../../helpers/RequestApi";
import { GET, POST } from "../../../../utils/MethodUtils";
import {
  FARTICLE_URL,
  FILTER_FILTER_ARTICLE_FARTICLE_URL,
  POST_PUT_FILTER_VALUE_ARTICLE_URL,
} from "../../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import FArticleComponent from "../../fArticle/FArticleComponent";
import { Grid, TextField, useMediaQuery, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import FilterAdminProductFilterValueComponent from "./form/productFilterForm/FilterAdminProductFilterValueComponent";
import getErrorApi from "../../../../helpers/GetErrorApi";
import SearchIcon from "@mui/icons-material/Search";
import { useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../../helpers/SearchParamHelper";
import { STORAGE_LOCK } from "../../../../utils/StorageUtils";
import { getNeedToSendRequest } from "../../../../helpers/FilterHelper";

interface State {
  isCustomOverflow: boolean;
  containerHeight: number;
  onlyFilterArticle: boolean;
}

interface State2 {
  searchCustomFArticle: Function;
  customFArticle: FArticleInterface | undefined;
  index: number;
  back: any;
  save: any;
}

const BackNextComponent: React.FC<State2> = React.memo(
  ({ searchCustomFArticle, customFArticle, index, back, save }) => {
    const { t } = useTranslation();

    return (
      <>
        <Box>
          {customFArticle ? (
            <LoadingButton onClick={() => searchCustomFArticle(true)}>
              {t("word.cancel")}
            </LoadingButton>
          ) : (
            <LoadingButton disabled={index === 0} onClick={back}>
              {t("word.back")}
            </LoadingButton>
          )}
        </Box>
        <Box>
          <LoadingButton variant="contained" onClick={save}>
            {t("word.save")}
          </LoadingButton>
        </Box>
      </>
    );
  }
);

const FilterAdminProductsComponent: React.FC<State> = React.memo(
  ({ isCustomOverflow, containerHeight, onlyFilterArticle }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const refreshPage = useAppSelector(
      (state: RootState) => state.globalState.refreshPage
    );
    const nbFilterArticle = useAppSelector(
      (state: RootState) => state.globalState.nbFilterArticle
    );
    const nbFilterArticle2 = useAppSelector(
      (state: RootState) => state.globalState.nbFilterArticle2
    );
    const newBrowser = useAppSelector(
      (state: RootState) => state.globalState.newBrowser
    );
    const [heightFilter, setHeightFilter] = React.useState(0);
    const [heightSearch, setHeightSearch] = React.useState(0);
    const [heightBackSave, setHeightBackSave] = React.useState(0);
    const refHeightFilter: any = useRef(undefined);
    const refHeightSearch: any = useRef(undefined);
    const refBackSave: any = useRef(undefined);
    const refForm: any = useRef(undefined);
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const [init, setInit] = React.useState(false);
    const [isSearchingCustomFArticle, setIsSearchingCustomFArticle] =
      React.useState(() => {
        const newSearchParams = searchParamToObject(searchParams);
        return (
          newSearchParams.hasOwnProperty("search") &&
          newSearchParams.search.trim() !== ""
        );
      });
    const [searchFArticle, setSearchFArticle] = React.useState(() => {
      const newSearchParams = searchParamToObject(searchParams);
      if (newSearchParams.hasOwnProperty("search")) {
        return newSearchParams.search;
      }
      return "";
    });
    const [fArticles, setFArticles] = React.useState<
      FArticleInterface[] | undefined
    >(undefined);
    const [index, setIndex] = React.useState<number>(0);
    const [currentFArticle, setCurrentFArticle] = React.useState<
      FArticleInterface | undefined
    >(undefined);
    const [customFArticle, setCustomFArticle] = React.useState<
      FArticleInterface | undefined
    >(undefined);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const { t } = useTranslation();

    const addFArticles = React.useCallback(
      (newFArticles: FArticleInterface[]) => {
        setFArticles((x) => {
          if (!x) {
            x = [];
          }
          for (let newFArticle of newFArticles) {
            if (x.find((y) => y.arRef === newFArticle.arRef) === undefined) {
              x.push(newFArticle);
            }
          }
          return [...x];
        });
      },
      []
    );

    const updateFArticle = React.useCallback(
      (newFArticle: FArticleInterface) => {
        setFArticles((x) => {
          if (!x) {
            x = [];
          }
          const oldFArticleIndex = x.findIndex(
            (y) => y.arRef === newFArticle.arRef
          );
          if (oldFArticleIndex >= 0) {
            x[oldFArticleIndex] = newFArticle;
          }
          return [...x];
        });
      },
      []
    );

    const back = React.useCallback(() => {
      setIndex((x) => x - 1);
    }, []);

    const load = React.useCallback(
      async (force: boolean = false) => {
        setInit(true);
        if (
          (fArticles !== undefined && !force) ||
          (nbFilterArticle === 0 && onlyFilterArticle) ||
          (nbFilterArticle2 === 0 && !onlyFilterArticle)
        ) {
          dispatch(set({ refreshPage: false }));
          return;
        }
        let lock = localStorage.getItem(STORAGE_LOCK);
        if (!lock) {
          lock = (Math.random() + 1).toString(36).substring(2);
          localStorage.setItem(STORAGE_LOCK, lock);
        }
        const response = await requestApi({
          method: GET,
          path:
            FILTER_FILTER_ARTICLE_FARTICLE_URL +
            "?lock=" +
            lock +
            "&onlyFilterArticle=" +
            onlyFilterArticle,
          allowError: false,
          token: token,
        });
        if (response.statusCode === 200) {
          addFArticles(response.content);
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          toastr.error(t("word.error"), t("error.tryAgain"));
        }
        dispatch(set({ refreshPage: false }));
      },
      [
        addFArticles,
        dispatch,
        fArticles,
        nbFilterArticle,
        nbFilterArticle2,
        onlyFilterArticle,
        t,
        token,
      ]
    );

    const updateCurrentFArticle = React.useCallback(() => {
      if (!fArticles) {
        return;
      }
      setCurrentFArticle(fArticles[index]);
    }, [fArticles, index]);

    const handleChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFArticle(event.target.value);
      },
      []
    );

    const save = React.useCallback(async () => {
      let data: any;
      let needToSendRequest = false;
      if (!newBrowser) {
        data = refForm.current.getValue();
        needToSendRequest = getNeedToSendRequest(
          data,
          currentFArticle,
          customFArticle
        );
      }
      const fArticleId = customFArticle?.arRef ?? currentFArticle?.arRef;
      let currentIndex = index;
      if (!customFArticle) {
        setIndex((x) => {
          currentIndex = x + 1;
          return currentIndex;
        });
      } else {
        const newSearchParams = searchParamToObject(searchParams);
        if (newSearchParams.hasOwnProperty("search")) {
          delete newSearchParams.search;
        }
        setSearchParams(newSearchParams, {
          replace: true,
        });
        setSearchFArticle("");
      }
      if (needToSendRequest || !onlyFilterArticle) {
        const response = await requestApi({
          method: POST,
          path: POST_PUT_FILTER_VALUE_ARTICLE_URL,
          allowError: false,
          token: token,
          body: {
            fArticleId: fArticleId,
            filters: data,
          },
          timeout: 30_000,
        });
        if (response.statusCode === 200) {
          updateFArticle(response.content.fArticle);
          dispatch(set({ nbFilterArticle: response.content.nbFilterArticle }));
          dispatch(
            set({ nbFilterArticle2: response.content.nbFilterArticle2 })
          );
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
      } else if (newBrowser) {
        const response = await requestApi({
          method: GET,
          path: FARTICLE_URL + "/" + fArticleId,
          allowError: true,
          token: token,
        });
        if (response.statusCode === 200) {
          updateFArticle(response.content);
        }
      }
      if (
        !refreshPage &&
        ((nbFilterArticle && nbFilterArticle > 0) ||
          (nbFilterArticle2 && nbFilterArticle2 > 0)) &&
        fArticles &&
        currentIndex >= fArticles.length - 2
      ) {
        dispatch(set({ refreshPage: true }));
      }
    }, [
      currentFArticle,
      customFArticle,
      dispatch,
      fArticles,
      index,
      nbFilterArticle,
      nbFilterArticle2,
      newBrowser,
      onlyFilterArticle,
      refreshPage,
      searchParams,
      setSearchParams,
      t,
      token,
      updateFArticle,
    ]);

    const searchCustomFArticle = React.useCallback(
      (reset: boolean) => {
        const newSearchParams = searchParamToObject(searchParams);
        newSearchParams.search = searchFArticle.trim();
        if (reset) {
          newSearchParams.search = "";
          setSearchFArticle("");
        }
        setSearchParams(newSearchParams, {
          replace: true,
        });
      },
      [searchFArticle, searchParams, setSearchParams]
    );

    const loadCustomFArticle = React.useCallback(async () => {
      const newSearchParams = searchParamToObject(searchParams);
      const search = newSearchParams.search;
      if (search === "" || search === undefined) {
        setCustomFArticle(undefined);
        return;
      }
      setIsSearchingCustomFArticle(true);
      const response = await requestApi({
        method: GET,
        path: FARTICLE_URL + objectToQuery({ keywords: search }),
        allowError: false,
        paginate: false,
        token: token,
        timeout: 30_000,
      });
      if (response.statusCode === 200) {
        if (response.content.fArticles.length === 1) {
          setCustomFArticle(response.content.fArticles[0]);
        } else {
          toastr.error(t("word.error"), t("word.noResult"));
        }
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setIsSearchingCustomFArticle(false);
    }, [searchParams, t, token]);

    const onPressKey = React.useCallback(
      (e: any) => {
        // @ts-ignore
        const target = e.target;
        if (
          refreshPage ||
          (target instanceof HTMLInputElement &&
            // @ts-ignore
            e.target.type.toLowerCase() === "text" &&
            // @ts-ignore
            e.target.tagName.toLowerCase() === "input") ||
          e.target?.classList.contains("ck-content")
        ) {
          return;
        }
        if (e.key === "Enter") {
          save();
        }
      },
      [refreshPage, save]
    );

    React.useEffect(() => {
      load();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (init && refreshPage) {
        load(true);
      }
    }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      updateCurrentFArticle();
    }, [fArticles, index]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setHeightFilter(refHeightFilter.current?.clientHeight ?? 0);
      setHeightSearch(refHeightSearch.current?.clientHeight ?? 0);
      setHeightBackSave(refBackSave.current?.clientHeight ?? 0);
    }, undefined); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      loadCustomFArticle();
    }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      window.addEventListener("keydown", onPressKey);
      return () => {
        window.removeEventListener("keydown", onPressKey);
      };
    }, [refreshPage, save]); // eslint-disable-line react-hooks/exhaustive-deps

    let thisHeightBackSave = heightBackSave ?? 0;
    if (!newBrowser) {
      thisHeightBackSave = 0;
    }

    return (
      <>
        <Grid container spacing={1} sx={{ maxHeight: "inherit" }}>
          <Grid item xs={12} ref={refHeightSearch}>
            <Box sx={{ display: "flex" }}>
              <TextField
                autoComplete="off"
                sx={{ width: "100%" }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    searchCustomFArticle(false);
                  }
                }}
                type="text"
                value={searchFArticle}
                onChange={handleChange}
                label={t("word.search")}
              />
              <LoadingButton
                variant="contained"
                loading={refreshPage}
                onClick={() => searchCustomFArticle(false)}
              >
                <SearchIcon />
              </LoadingButton>
            </Box>
            <Divider sx={{ paddingTop: 1 }} />
          </Grid>
          {!isSearchingCustomFArticle && (currentFArticle || customFArticle) && (
            <>
              {newBrowser && (
                <Box
                  ref={refBackSave}
                  sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BackNextComponent
                    searchCustomFArticle={searchCustomFArticle}
                    customFArticle={customFArticle}
                    index={index}
                    back={back}
                    save={save}
                  />
                </Box>
              )}
              <Grid
                item
                xs={12}
                md={newBrowser ? 12 : 7}
                sx={{
                  overflow: "auto",
                  maxHeight: "inherit",
                  ...(isCustomOverflow && {
                    padding: "0!important",
                    maxHeight:
                      containerHeight - heightSearch - thisHeightBackSave,
                  }),
                }}
              >
                <FArticleComponent
                  initFArticle={customFArticle ?? currentFArticle}
                  scrollBottomTabs={false}
                  displayAfterContent={false}
                  setFArticleInit={
                    customFArticle
                      ? setCustomFArticle
                      : currentFArticle
                      ? setCurrentFArticle
                      : undefined
                  }
                />
                {isSmall && <Divider />}
              </Grid>
              {!newBrowser && (
                <Grid
                  item
                  xs={12}
                  md={5}
                  sx={{
                    maxHeight: "inherit",
                    ...(isCustomOverflow && {
                      padding: "0!important",
                      maxHeight: containerHeight - heightSearch,
                    }),
                  }}
                >
                  <Box
                    ref={refHeightFilter}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      ...(isCustomOverflow && {
                        padding: 1,
                        paddingBottom: 0,
                      }),
                    }}
                  >
                    <BackNextComponent
                      searchCustomFArticle={searchCustomFArticle}
                      customFArticle={customFArticle}
                      index={index}
                      back={back}
                      save={save}
                    />
                  </Box>
                  <Box
                    sx={{
                      overflow: "auto",
                      ...(isCustomOverflow && {
                        maxHeight:
                          containerHeight - heightFilter - heightSearch,
                        paddingX: 1,
                      }),
                    }}
                  >
                    <FilterAdminProductFilterValueComponent
                      ref={refForm}
                      fArticle={customFArticle ?? currentFArticle}
                    />
                    <Box sx={{ paddingBottom: 10, width: "100%" }} />
                  </Box>
                </Grid>
              )}
            </>
          )}
        </Grid>
        {nbFilterArticle !== undefined &&
          nbFilterArticle > 0 &&
          !(currentFArticle || customFArticle) &&
          (fArticles === undefined || isSearchingCustomFArticle) && (
            <>
              <SearchComponent nbColumn={1} nbLines={1} height={500} />
            </>
          )}
        {!currentFArticle &&
          !customFArticle &&
          fArticles !== undefined &&
          !isSearchingCustomFArticle && (
            <Typography component="p" variant="h5" sx={{ textAlign: "center" }}>
              {t("word.noResult")}
            </Typography>
          )}
      </>
    );
  }
);

export default FilterAdminProductsComponent;
