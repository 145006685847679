import * as React from "react";
import { useParams } from "react-router-dom";
import ContentDisplayComponent from "../components/content/ContentDisplayComponent";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import { RootState } from "../app/store";
import { DriverInterface } from "../interfaces/FArticleInterface";
import { getTitle } from "../helpers/SearchParamHelper";
import Layout from "../components/common/Layout";
import {set} from "../app/globalSlice";

const BrandScreen: React.FC = React.memo(() => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const drivers = useAppSelector(
    (state: RootState) => state.globalState.drivers
  );
  const getBrand = React.useCallback((): DriverInterface | undefined => {
    return drivers?.find((x) => x.id.toString() === params.id);
  }, [drivers, params.id]);
  const [brand, setBrand] = React.useState<DriverInterface | undefined>(
    getBrand()
  );

  React.useEffect(() => {
    document.title = getTitle("brand");
    dispatch(set({ refreshPage: false }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setBrand(getBrand());
  }, [params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (brand) {
      document.title = brand.name;
    }
  }, [brand]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      {brand && <ContentDisplayComponent content={brand.content} />}
    </Layout>
  );
});

export default BrandScreen;
