import React from "react";
import IframeResizer from "@iframe-resizer/react";

interface State {
  data: any;
}

const IframeDisplayComponent: React.FC<State> = React.memo(({ data }) => {
  return (
    <IframeResizer
      license="GPLv3"
      src={data?.url}
      style={{ width: "100%", height: "100vh", border: "none" }}
      // @ts-ignore
      waitForLoad={true}
    />
  );
});

export default IframeDisplayComponent;
