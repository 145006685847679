import * as React from "react";
import { Card, CardContent, Chip, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { FOURNISSEUR_ARTICLE_URL } from "../../../utils/UrlsUtils";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import SearchComponent from "../SearchComponent";
import Typography from "@mui/material/Typography";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Box from "@mui/material/Box";
import { FournisseurArticleInterface } from "../../../interfaces/FArticleInterface";
import { getPropertiesStartWith } from "../../../helpers/FArticleHelper";
import { LoadingButton } from "@mui/lab";
import DoneIcon from "@mui/icons-material/Done";

interface State {
  initSearch: string;
  setFournisseurProduct: Function;
  refFournisseur: boolean;
}

export const FilterSearchFournisseurComponent: React.FC<State> = React.memo(
  ({ initSearch, setFournisseurProduct, refFournisseur }) => {
    const { t } = useTranslation();
    const [search, setSearch] = React.useState<string>(initSearch);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [fournisseurProducts, setFournisseurProducts] = React.useState<
      FournisseurArticleInterface[] | undefined | null
    >(undefined);

    const searchProducts = React.useCallback(async () => {
      const thisSearch = search.trim();
      if (!thisSearch) {
        return;
      }
      setFournisseurProducts(undefined);
      const response = await requestApi({
        method: GET,
        path:
          FOURNISSEUR_ARTICLE_URL +
          objectToQuery({
            search: thisSearch,
            refFournisseur: refFournisseur ? "1" : "0",
          }),
        allowError: true,
        token: token,
      });
      if (response.statusCode === 200) {
        setFournisseurProducts(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
    }, [refFournisseur, search, t, token]);

    const onSelectProduct = React.useCallback(
      (fournisseurProduct: FournisseurArticleInterface) => {
        setFournisseurProduct(fournisseurProduct);
        setFournisseurProducts(null);
      },
      [setFournisseurProduct]
    );

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        searchProducts();
      }, 500);
      return () => clearTimeout(timeoutTyping);
    }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      searchProducts();
    }, [refFournisseur]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setSearch(initSearch);
    }, [initSearch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {search.trim() && fournisseurProducts !== null && (
          <>
            {fournisseurProducts && fournisseurProducts.length > 0 ? (
              <Grid container spacing={1}>
                {fournisseurProducts.map(
                  (fournisseurProduct, indexFournisseurProduct) => (
                    <Grid item xs={12} key={indexFournisseurProduct}>
                      <Card variant="outlined">
                        <CardContent>
                          {fournisseurProduct?.existSage &&
                            fournisseurProduct.existSage.map(
                              (ref, indexRef) => (
                                <Chip
                                  key={indexRef}
                                  label={ref}
                                  variant="outlined"
                                  color="error"
                                />
                              )
                            )}
                          <Typography>
                            {fournisseurProduct.refFabricant}
                          </Typography>
                          <Typography>{fournisseurProduct.eanUpc}</Typography>
                          <ul>
                            {getPropertiesStartWith(
                              fournisseurProduct,
                              "idArtCatalogue"
                            ).map((prop: string) => (
                              <React.Fragment key={prop}>
                                {/*@ts-ignore*/}
                                {fournisseurProduct[prop] !== null && (
                                  <li>
                                    {/*@ts-ignore*/}
                                    {fournisseurProduct[prop].ctNum +
                                      ": " +
                                      // @ts-ignore
                                      fournisseurProduct[prop].designation +
                                      " [" +
                                      t("word.refSupplier") +
                                      ": " +
                                      // @ts-ignore
                                      fournisseurProduct[prop]?.refFournisseur +
                                      "]"}
                                  </li>
                                )}
                              </React.Fragment>
                            ))}
                          </ul>
                          <Box sx={{ textAlign: "center" }}>
                            <LoadingButton
                              variant="contained"
                              sx={{
                                borderRadius: "50%",
                                minWidth: "auto",
                                padding: "12px",
                              }}
                              onClick={() => {
                                onSelectProduct(fournisseurProduct);
                              }}
                            >
                              <DoneIcon fontSize="small" />
                            </LoadingButton>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                )}
              </Grid>
            ) : fournisseurProducts && fournisseurProducts.length === 0 ? (
              <Box sx={{ textAlign: "center" }}>
                <Typography sx={{ fontSize: "10em" }} color="secondary">
                  404
                </Typography>
                <SentimentVeryDissatisfiedIcon
                  sx={{ fontSize: "10em" }}
                  color="secondary"
                />
              </Box>
            ) : (
              <SearchComponent nbColumn={1} nbLines={1} height={200} />
            )}
          </>
        )}
      </>
    );
  }
);
