import React, { forwardRef, useImperativeHandle } from "react";
import {
  CategoryInterface,
  FilterCategoryInterface,
} from "../../../interfaces/CategoryInterface";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { useSearchParams } from "react-router-dom";
import { STORAGE_GLOBAL_SEARCH_BY_RAYON } from "../../../utils/StorageUtils";
import { set } from "../../../app/globalSlice";

interface State {
  category: CategoryInterface | undefined;
  apply: Function;
  setInitSort: Function;
}

const SortProductsComponent = React.memo(
  forwardRef(({ category, apply, setInitSort }: State, ref) => {
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const getReferenceFilterCategory =
      React.useCallback((): FilterCategoryInterface[] => {
        if (!category) {
          return [];
        }
        return category.filterCategories?.filter((f) => f.reference) ?? [];
      }, [category]);
    const [referenceFilterCategories, setReferenceFilterCategories] =
      React.useState<FilterCategoryInterface[]>(getReferenceFilterCategory());
    const getFilterItemValue = React.useCallback(
      (referenceFilterCategory: FilterCategoryInterface): string => {
        return (
          "sortFilters." +
          referenceFilterCategory.filter.id +
          "|" +
          JSON.stringify({
            order: "asc",
            // https://stackoverflow.com/questions/17051709/no-mapping-found-for-field-in-order-to-sort-on-in-elasticsearch
            // https://www.elastic.co/guide/en/elasticsearch/reference/current/sort-search-results.html#_ignoring_unmapped_fields
            // https://www.elastic.co/guide/en/elasticsearch/reference/current/mapping-types.html#_core_datatypes
            unmapped_type: "long",
          })
        );
      },
      []
    );
    const dispatch = useAppDispatch();
    const getSort = React.useCallback((): string => {
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key, value] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          return key.replace("order[", "").replace("]", "") + "|" + value;
        }
      }
      if (referenceFilterCategories.length === 0) {
        return "";
      }
      return getFilterItemValue(referenceFilterCategories[0]);
    }, [getFilterItemValue, referenceFilterCategories, searchParams]);
    const [sort, setSort] = React.useState<string>(getSort());
    const catTarif = useAppSelector(
      (state: RootState) => state.globalState.catTarif
    );
    const handleChange = React.useCallback(
      (event: SelectChangeEvent) => {
        if (event.target.value === "") {
          dispatch(set({ globalSearchByRayon: false }));
          localStorage.setItem(STORAGE_GLOBAL_SEARCH_BY_RAYON, "false");
        } else if (event.target.value.startsWith("categories|")) {
          dispatch(set({ globalSearchByRayon: true }));
          localStorage.setItem(STORAGE_GLOBAL_SEARCH_BY_RAYON, "true");
        }
        setSort(event.target.value as string);
      },
      [dispatch]
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        return sort;
      },
    }));

    React.useEffect(() => {
      setSort(getSort());
    }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setInitSort(true);
      setTimeout(() => {
        apply();
      });
    }, [sort]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setReferenceFilterCategories(getReferenceFilterCategory());
    }, [category]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="sort-products">{t("field.sort")}</InputLabel>
          <Select
            labelId="sort-products"
            value={sort}
            label={t("field.sort")}
            onChange={handleChange}
          >
            {referenceFilterCategories.length === 0 ? (
              <MenuItem value="">
                <em>{t("word.none")}</em>
              </MenuItem>
            ) : (
              referenceFilterCategories.map(
                (referenceFilterCategory, indexReferenceFilterCategory) => (
                  <MenuItem
                    key={indexReferenceFilterCategory}
                    value={getFilterItemValue(referenceFilterCategory)}
                  >
                    {referenceFilterCategory.filter.name}
                  </MenuItem>
                )
              )
            )}
            {!category && (
              <MenuItem
                value={
                  "categories" +
                  "|" +
                  JSON.stringify({
                    order: "asc",
                  })
                }
              >
                {t("word.rayon")}
              </MenuItem>
            )}
            <MenuItem
              value={
                "price" +
                catTarif +
                "|" +
                JSON.stringify({
                  order: "asc",
                })
              }
            >
              {t("sort.price.asc")}
            </MenuItem>
            <MenuItem
              value={
                "price" +
                catTarif +
                "|" +
                JSON.stringify({
                  order: "desc",
                })
              }
            >
              {t("sort.price.desc")}
            </MenuItem>
            {isAdmin &&
              ["lastBuy", "lastSell"].map((prop, indexProp) => {
                return ["asc", "desc"].map((order, indexOrder) => (
                  <MenuItem
                    key={indexProp + "-" + indexOrder}
                    value={
                      prop +
                      "|" +
                      JSON.stringify({
                        order: order,
                      })
                    }
                  >
                    {t("sort." + prop + "." + order)}
                  </MenuItem>
                ));
              })}
          </Select>
        </FormControl>
      </>
    );
  })
);

export default SortProductsComponent;
