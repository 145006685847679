import * as React from "react";
import { FArticleInterface } from "../../../interfaces/FArticleInterface";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Typography from "@mui/material/Typography";
import CopyClipboardComponent from "../CopyClipboardComponent";

interface State {
  fArticle: FArticleInterface | undefined;
}

const FArticleCreatedByComponent: React.FC<State> = React.memo(
  ({ fArticle }) => {
    const { t } = useTranslation();
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );

    return (
      <>
        {isAdmin && (
          <Typography>
            {t("word.createdBy") + ": "}
            <CopyClipboardComponent
              className="RobotoMono"
              component="span"
              text={fArticle?.fArticleProp?.createdBy ?? ""}
            />
          </Typography>
        )}
      </>
    );
  }
);

export default FArticleCreatedByComponent;
