import React, { useRef } from "react";
import { DriverInterface } from "../../../interfaces/FArticleInterface";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { InputInterface } from "../../../interfaces/InputInterface";
import { Grid, TextField } from "@mui/material";
import CropperComponent from "../file/CropperComponent";
import { getUrlConstructorImage } from "../../../helpers/FileHelper";
import { IMAGE_SIZE_FULL, IMAGE_SIZE_NORMAL } from "../../../utils/FileUtils";
import notEmptyValidator from "../../../helpers/validator/NotEmptyValidator";
import { jsonToFormData, requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { DRIVER_URL } from "../../../utils/UrlsUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { updateDriver } from "../../../app/globalSlice";
import ContentComponent from "../../content/ContentComponent";
import ImageQWantComponent from "../fArticle/qWant/ImageQWantComponent";
import { QWantImageInterface } from "../fArticle/qWant/SingleImageQWantComponent";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

interface State {
  driver: DriverInterface | undefined;
  handleClose: any;
}

interface FormState {
  name: InputInterface;
  link: InputInterface;
  showInCatalog: InputInterface;
}

const BrandFormComponent: React.FC<State> = React.memo(
  ({ driver, handleClose }) => {
    const { t } = useTranslation();
    const [showOriginalImage, setShowOriginalImage] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const newImage: any = useRef();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const dispatch = useAppDispatch();
    const refContent: any = useRef();
    const [url, setUrl] = React.useState<string | undefined>(undefined);

    const getDefaultValues = React.useCallback((): FormState => {
      return {
        name: {
          value: driver?.name,
          error: "",
        },
        link: {
          value: driver?.link,
          error: "",
        },
        showInCatalog: {
          value: driver?.showInCatalog,
          error: "",
        },
      };
    }, [driver?.name, driver?.link, driver?.showInCatalog]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());

    const save = React.useCallback(async () => {
      setLoading(true);
      const nameError = notEmptyValidator(values.name.value);
      if (nameError) {
        const newValue: FormState = { ...values };
        newValue.name.error = nameError;
        setValues(newValue);
        setLoading(false);
        return undefined;
      }
      const brandApi = jsonToFormData({
        name: values.name.value,
        link: values.link.value,
        showInCatalog: values.showInCatalog.value,
        content: await refContent.current.getValue(),
        ...(driver?.id && {
          oldId: driver.id.toString(),
        }),
      });
      const image: any = await newImage.current.getValue();
      if (image) {
        brandApi.append("file", image);
      }
      const response = await requestApi({
        method: POST,
        path: DRIVER_URL,
        allowError: true,
        token: token,
        body: brandApi,
        formData: true,
      });
      if (response.statusCode === 201) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.driver_updated")
        );
        dispatch(updateDriver(response.content));
        handleClose();
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [dispatch, driver?.id, handleClose, t, token, values]);

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const imageAdded = React.useCallback(() => {
      setShowOriginalImage(false);
    }, []);

    const deleteImage = React.useCallback(() => {
      setShowOriginalImage(true);
    }, []);

    const customAddImage = React.useCallback((data: QWantImageInterface[]) => {
      setUrl(data[0].media);
    }, []);

    const handleChangeCheckbox = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.checked, error: "" },
            };
          });
        },
      []
    );

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [driver]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box sx={{ marginBottom: 1 }}>
              <ImageQWantComponent
                initKeywords={[driver?.name + " logo"]}
                customAddImage={customAddImage}
                singleImage={true}
              />
            </Box>
            <CropperComponent
              ref={newImage}
              minCropBox={IMAGE_SIZE_NORMAL}
              imageAdded={imageAdded}
              deleteImage={deleteImage}
              aspectRatio={4 / 3}
              url={url}
            />
            {showOriginalImage && driver?.images && (
              <img
                src={
                  (process.env.REACT_APP_API_URL ?? "") +
                    getUrlConstructorImage(driver, IMAGE_SIZE_FULL) ?? ""
                }
                style={{
                  maxWidth: "100%",
                  margin: "auto",
                  display: "block",
                  border: "1px dashed gray",
                }}
                alt={driver?.name}
                loading="lazy"
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth={true}
              autoComplete="off"
              error={!!values.name.error}
              helperText={t(values.name.error ?? "")}
              sx={{ width: "100%" }}
              required
              type="text"
              value={values.name.value}
              onChange={handleChange("name")}
              label={t("field.name")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth={true}
              autoComplete="off"
              error={!!values.link.error}
              helperText={t(values.link.error ?? "")}
              sx={{ width: "100%" }}
              required
              type="text"
              value={values.link.value}
              onChange={handleChange("link")}
              label={t("field.link")}
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.showInCatalog.value}
                    onChange={handleChangeCheckbox("showInCatalog")}
                  />
                }
                label={t("sentence.showBrandOnCatalog")}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <ContentComponent
              initContent={driver ? driver.content : ""}
              preventChangeUrlTab={true}
              showEdit={false}
              edit={true}
              ref={refContent}
              showSaveCancel={false}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <LoadingButton disabled={loading} onClick={handleClose}>
              {t("word.cancel")}
            </LoadingButton>
            <LoadingButton variant="contained" loading={loading} onClick={save}>
              {t("word.save")}
            </LoadingButton>
          </Grid>
        </Grid>
      </>
    );
  }
);

export default BrandFormComponent;
