import * as React from "react";
import { useImperativeHandle, useRef } from "react";
import {
  FArticleSmallInterface,
  FournisseurArticleInterface,
} from "../../../interfaces/FArticleInterface";
import Box from "@mui/material/Box";
import {
  getUrlFilterImageImage,
  getUrlLogoImage,
} from "../../../helpers/FileHelper";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import FArticleDriverComponent from "./FArticleDriverComponent";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  onlyConstructor?: boolean | undefined;
  constructorSize?: number;
  sx?: SxProps<Theme>;
  edit?: boolean | undefined;
  setFArticle?: Function | undefined;
  setFullSizeConstructor?: Function;
  createFrom?: FArticleSmallInterface | undefined;
  temporaryIcecat?: any;
  fournisseurProduct?: FournisseurArticleInterface;
  isSmallArticle?: boolean;
}

const FArticleLogoComponent = React.memo(
  React.forwardRef(
    (
      {
        fArticle,
        onlyConstructor,
        constructorSize,
        sx,
        edit,
        setFArticle,
        setFullSizeConstructor,
        createFrom,
        temporaryIcecat,
        fournisseurProduct,
        isSmallArticle,
      }: State,
      ref
    ) => {
      const style: any = {
        maxWidth: "35px",
        maxHeight: "35px",
        margin: 0,
        padding: 5,
        display: "block",
      };
      const refConstructor: any = useRef();

      useImperativeHandle(ref, () => ({
        getValue() {
          return refConstructor.current.getValue();
        },
      }));

      return (
        <>
          {(fArticle?.fArticleProp || createFrom) && (
            <>
              {!onlyConstructor ? (
                <>
                  {((fArticle?.fArticleProp?.logos &&
                    fArticle?.fArticleProp?.logos.length > 0) ||
                    (fArticle?.fArticleProp?.filterValueArticles &&
                      fArticle?.fArticleProp?.filterValueArticles.length >
                        0)) && (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      {fArticle?.fArticleProp?.logos?.map((logo, indexLogo) => (
                        <img
                          key={indexLogo}
                          src={
                            (process.env.REACT_APP_API_URL ?? "") +
                            getUrlLogoImage(logo)
                          }
                          style={style}
                          alt={logo}
                          loading="lazy"
                        />
                      ))}
                      {fArticle?.fArticleProp?.filterValueArticles
                        ?.filter(
                          (filterValueArticle, indexFilterValueArticle) =>
                            filterValueArticle.filterValue?.show &&
                            filterValueArticle.filterValue?.filterImage &&
                            fArticle?.fArticleProp?.filterValueArticles?.findIndex(
                              (filterValueArticle2) =>
                                filterValueArticle.filterValue?.filterImage
                                  ?.id ===
                                filterValueArticle2.filterValue?.filterImage?.id
                            ) === indexFilterValueArticle
                        )
                        ?.map((filterValueArticle, indexFilterValueArticle) => (
                          <img
                            key={indexFilterValueArticle}
                            src={getUrlFilterImageImage(
                              // @ts-ignore
                              filterValueArticle.filterValue.filterImage
                            )}
                            style={style}
                            alt={
                              // @ts-ignore
                              filterValueArticle.filterValue.filterImage.name
                            }
                            loading="lazy"
                          />
                        ))}
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "inline-block",
                      width: "100%",
                      position: "relative",
                      zIndex: 1,
                      ...(sx && { ...sx }),
                    }}
                  >
                    <FArticleDriverComponent
                      ref={refConstructor}
                      fArticle={fArticle}
                      setFArticle={setFArticle}
                      constructorSize={constructorSize}
                      edit={edit}
                      setFullSizeConstructor={setFullSizeConstructor}
                      temporaryIcecat={temporaryIcecat}
                      fournisseurProduct={fournisseurProduct}
                      isSmallArticle={isSmallArticle}
                    />
                  </Box>
                </>
              )}
            </>
          )}
        </>
      );
    }
  )
);

export default FArticleLogoComponent;
