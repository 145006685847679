import React from "react";
import { ContentInterface } from "../../interfaces/ContentInterface";
import {
  Accordion,
  AccordionDetails,
  Button,
  Container,
  useTheme,
} from "@mui/material";
import {
  CONTAINER_FLUID,
  CONTENT_CAROUSEL,
  CONTENT_CKEDITOR,
  CONTENT_FARTICLE,
  CONTENT_IFRAME,
  CONTENT_MAP,
  CONTENT_PDF,
  CONTENT_VIDEO,
} from "../../utils/ContentUtils";
import Box from "@mui/material/Box";
import ContentDisplayFArticleComponent from "./ContentDisplayFArticleComponent";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import MapDisplayComponent from "../common/MapDisplayComponent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { searchParamToObject } from "../../helpers/SearchParamHelper";
import { useSearchParams } from "react-router-dom";
import TabPanelComponent from "../common/TabPanelComponent";
import { FArticleSmallInterface } from "../../interfaces/FArticleInterface";
import { formatCkEditor } from "../../helpers/CkEditorHelper";
import PdfDisplayComponent from "../common/PdfDisplayComponent";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CarouselDisplayComponent from "../common/CarouselDisplayComponent";
import VideoDisplayComponent from "../common/VideoDisplayComponent";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { set } from "../../app/globalSlice";
import { STORAGE_DISMISS_CONTENT } from "../../utils/StorageUtils";
import IframeDisplayComponent from "../common/IframeDisplayComponent";

const stringSimilarity = require("string-similarity");
const slugify = require("slugify");

interface State {
  content: ContentInterface | null | undefined;
  preventChangeUrlTab?: boolean;
  fArticle?: FArticleSmallInterface | undefined;
  contentName?: string;
  setTabValue?: Function;
  noPadding?: boolean;
}

interface State2 {
  column: any;
  fArticle?: FArticleSmallInterface | undefined;
  contentName?: string;
  path: string;
}

const ContentDisplayMainComponent: React.FC<State2> = React.memo(
  ({ column, fArticle, contentName, path }) => {
    const { t } = useTranslation();
    const dismissContent = useAppSelector(
      (state: RootState) => state.globalState.dismissContent
    );
    const dispatch = useAppDispatch();

    const dismiss = React.useCallback(() => {
      if (!contentName) {
        return;
      }
      let thisDismissContent =
        dismissContent?.map((d) => {
          return { ...d };
        }) ?? [];
      thisDismissContent.push({
        contentName: contentName,
        path: path,
      });
      thisDismissContent = thisDismissContent.filter(
        (a, i) =>
          thisDismissContent.findIndex(
            (s) => a.path === s.path && a.contentName === s.contentName
          ) === i
      );
      dispatch(set({ dismissContent: thisDismissContent }));
      localStorage.setItem(
        STORAGE_DISMISS_CONTENT,
        JSON.stringify(thisDismissContent)
      );
    }, [contentName, dismissContent, dispatch, path]);

    return (
      <>
        {(column?.notActive === undefined || column?.notActive !== true) &&
          dismissContent?.find(
            (d) => d.contentName === contentName && d.path === path
          ) === undefined && (
            <>
              {column.contentType === CONTENT_CKEDITOR ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatCkEditor(column.data),
                  }}
                />
              ) : column.contentType === CONTENT_FARTICLE ? (
                <ContentDisplayFArticleComponent
                  data={column.data}
                  fArticle={fArticle}
                  contentName={contentName}
                />
              ) : column.contentType === CONTENT_MAP ? (
                <MapDisplayComponent
                  query={column.data.query}
                  height={column.data.height}
                />
              ) : column.contentType === CONTENT_PDF ? (
                <PdfDisplayComponent
                  base64={
                    process.env.REACT_APP_API_URL + "/DATA" + column.data.file
                  }
                  nbPagePreview={column.data.nbPagePreview}
                />
              ) : column.contentType === CONTENT_CAROUSEL ? (
                <CarouselDisplayComponent data={column.data} />
              ) : column.contentType === CONTENT_VIDEO ? (
                <VideoDisplayComponent data={column.data} />
              ) : column.contentType === CONTENT_IFRAME ? (
                <IframeDisplayComponent data={column.data} />
              ) : (
                <Typography sx={{ color: "red" }}>
                  Create view to display {column.contentType}
                </Typography>
              )}
              {column?.dismissible && (
                <Box sx={{ textAlign: "right" }}>
                  <Button onClick={dismiss}>{t("word.iAnderstood")}</Button>
                </Box>
              )}
            </>
          )}
      </>
    );
  }
);

const ContentDisplayComponent: React.FC<State> = React.memo(
  ({
    content,
    preventChangeUrlTab,
    fArticle,
    contentName,
    setTabValue,
    noPadding,
  }) => {
    const theme = useTheme();
    const [searchParams, setSearchParams] = useSearchParams();
    const getTabs = React.useCallback(() => {
      let tabs: any = null;
      if (content) {
        if (content.hasOwnProperty("content")) {
          tabs = content?.content?.tabs;
        } else {
          // @ts-ignore
          tabs = content?.tabs;
        }
      }
      return tabs;
    }, [content]);
    const [tabs, setTabs] = React.useState<any[] | null>(getTabs());
    const getValue = React.useCallback(() => {
      if (preventChangeUrlTab) {
        return 0;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      if (
        searchParamsObject.tab &&
        isNaN(searchParamsObject.tab) &&
        content?.content
      ) {
        const result = stringSimilarity.findBestMatch(
          searchParamsObject.tab,
          tabs?.map((t: any) => slugify(t.name, { lower: true }))
        );
        return result.bestMatchIndex;
      }
      return searchParamsObject.tab ? Number(searchParamsObject.tab) : 0;
    }, [content?.content, preventChangeUrlTab, searchParams, tabs]);
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const [value, setValue] = React.useState(getValue());

    const handleChange = React.useCallback(
      (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        if (!preventChangeUrlTab) {
          const newSearchParams = searchParamToObject(searchParams);
          newSearchParams.tab = newValue.toString();
          setSearchParams(newSearchParams, {
            replace: true,
          });
        }
      },
      [preventChangeUrlTab, searchParams, setSearchParams]
    );

    React.useEffect(() => {
      setValue(getValue());
      setTabs(getTabs());
    }, [content]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (setTabValue) {
        setTabValue(value);
      }
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    return tabs ? (
      <>
        {tabs.length > 1 && (
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              scrollButtons="auto"
              variant={!isSmall ? "standard" : "scrollable"}
              centered={!isSmall}
            >
              {tabs.map((tab: any, indexTab: number) => (
                <Tab label={tab.name} key={indexTab} />
              ))}
            </Tabs>
          </Box>
        )}
        {tabs.map((tab: any, indexTab: number) => (
          <TabPanelComponent
            sx={{ marginTop: 1 }}
            value={value}
            index={indexTab}
            key={indexTab}
          >
            {tab.rows.map((row: any, indexRow: number) => (
              <Container
                className="ck-content"
                sx={{
                  display: "flex",
                  marginTop: 1,
                  ...(row.container === CONTAINER_FLUID && {
                    maxWidth: "100%!important",
                  }),
                  ...(isSmall && {
                    flexWrap: "wrap",
                  }),
                  ...(noPadding && {
                    padding: "0!important",
                  }),
                }}
                key={indexRow}
              >
                {row.columns.map((column: any, columnIndex: number) => (
                  <Box
                    key={columnIndex}
                    sx={{
                      flex: 1,
                      maxWidth: "100%",
                      ...(isSmall && { flexBasis: "100%" }),
                    }}
                  >
                    {column.accordion ? (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon sx={{ color: "white" }} />
                          }
                          sx={{
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          <Box
                            sx={{
                              color: theme.palette.primary.contrastText,
                              width: "100%",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: formatCkEditor(column.accordion),
                            }}
                          />
                        </AccordionSummary>
                        <AccordionDetails>
                          <ContentDisplayMainComponent
                            column={column}
                            contentName={contentName}
                            fArticle={fArticle}
                            path={indexRow + "_" + columnIndex}
                          />
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      <ContentDisplayMainComponent
                        column={column}
                        contentName={contentName}
                        fArticle={fArticle}
                        path={indexRow + "_" + columnIndex}
                      />
                    )}
                  </Box>
                ))}
              </Container>
            ))}
          </TabPanelComponent>
        ))}
      </>
    ) : (
      <></>
    );
  }
);

export default ContentDisplayComponent;
